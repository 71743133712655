export const environment = {
  applicationInsights: {
    appId: "Identity",
    enableConsoleLogging: true,
    instrumentationKey: "48e6346f-6e23-4ad4-b8c7-2ad4620aa862",
    rethrowException: false,
  },
  auth: {
    authority: "https://alpha-auth.motorcity.systems",
    clientId: "id",
  },
  gaugeBaseUrl: "https://alpha-gauge-api.motorcity.systems",
  identityBaseUrl: "https://alpha-identity-api.motorcity.systems",
  geolocationBaseApiUrl: "https://alpha-geoloc-api.motorcity.systems",
  adminBaseUrl: "https://alpha-admin-api.motorcity.systems",
  name: "alpha",
  production: true,
  version: {
    hash: "{{POST_BUILD_ENTERS_VERSION_HASH_HERE}}",
    version: "{{POST_BUILD_ENTERS_VERSION_NUMBER_HERE}}",
  },
  identityAppUrl: "https://alpha-identity.motorcity.systems",
};
