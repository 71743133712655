import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { AutofocusFixModule } from "ngx-autofocus-fix";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

import { DirtyCheckGuard } from "ng-mcs-forms";
import { NgMcsCommonUIModule } from "ng-mcs-common-ui";
import { NgMcsTableModule } from "ng-mcs-table";
import { McsDataTableModule } from "@motorcitysystems/core";

import { ClientListComponent } from "./components/client-list/client-list.component";
import { CreateClientComponent } from "./components/create-client/create-client.component";
import { DeleteClientComponent } from "./components/delete-client/delete-client.component";
import { SharedModule } from "../shared/shared.module";

const routes: Routes = [
  {
    path: "",
    component: ClientListComponent,
    pathMatch: "full",
  },
  {
    path: "create",
    component: CreateClientComponent,
    canDeactivate: [DirtyCheckGuard],
    pathMatch: "full",
  },
];

@NgModule({
  declarations: [ClientListComponent, DeleteClientComponent, CreateClientComponent],
  imports: [
    AutofocusFixModule,
    SharedModule,
    CommonModule,
    FontAwesomeModule,
    McsDataTableModule,
    NgbModalModule,
    NgMcsCommonUIModule,
    NgMcsTableModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
  ],
})
export class ClientsModule {}
