<app-header title="Create Client">
  <button class="btn btn-link py-0 px-1" back leftContent></button>
</app-header>

<div class="container py-2 flex-fill">
  <div class="row">
    <div class="col-6">
      <form [formGroup]="form">
        <div class="mb-3">
          <label class="form-label" for="clientName">Client Name <small class="text-danger">*</small></label>
          <input
            type="text"
            class="form-control"
            id="clientName"
            name="clientName"
            formControlName="clientName"
            [readonly]="created"
            autofocus
          />
          <small id="clientNameHelp" class="form-text text-muted">Friendly name for the client</small>
        </div>
        <div class="mb-3">
          <label class="form-label" for="clientId">Client ID <small class="text-danger">*</small></label>
          <div class="d-flex align-middle">
            <input
              type="text"
              class="form-control"
              id="clientId"
              name="clientId"
              formControlName="clientId"
              placeholder="**Auto Generated**"
              readonly
            />
            <button
              class="btn btn-link"
              title="Copy"
              [copy-clipboard]="form?.controls?.clientId?.value"
              (copied)="copied('Client ID')"
            >
              <fa-icon class="fixed" [icon]="['far', 'copy']" [fixedWidth]="true"></fa-icon>
            </button>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="clientSecret">Client Secret <small class="text-danger">*</small></label>
          <div class="d-flex align-middle">
            <input
              type="text"
              class="form-control"
              id="clientSecret"
              name="clientSecret"
              formControlName="clientSecret"
              placeholder="**Auto Generated**"
              readonly
            />
            <button
              class="btn btn-link"
              title="Copy"
              [copy-clipboard]="form?.controls?.clientSecret?.value"
              (copied)="copied('Client Secret')"
            >
              <fa-icon class="fixed" [icon]="['far', 'copy']" [fixedWidth]="true"></fa-icon>
            </button>
          </div>
        </div>
        <div>
          <small id="emailHelp" class="form-text text-danger"
            ><fa-icon [icon]="['far', 'exclamation-triangle']" pull="left" size="3x"></fa-icon> WARNING: The value of
            the secret cannot be retrieved. Please store the secret in a safe place before closing the window.</small
          >
        </div>
      </form>
    </div>
  </div>
</div>
<footer class="border-top p-2 text-end">
  <a *ngIf="created" class="btn btn-primary me-2" [routerLink]="['/clients']"> Close </a>
  <button *ngIf="!created" type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="create()">
    Create
  </button>
</footer>
